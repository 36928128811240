import { TextField, TextFieldProps } from '@mui/material';
import { useField, FieldHookConfig } from 'formik';
import { omit } from 'lodash';

export type TTTextFieldProps = {
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  required?: boolean;
} & TextFieldProps &
  FieldHookConfig<any>;

export default function TTTextField({ label, ...props }: TTTextFieldProps) {
  const [field, meta] = useField(props);
  return (
    <TextField
      error={!!meta.error}
      helperText={meta.error || props.helperText || ' '}
      fullWidth
      label={label}
      variant="standard"
      {...field}
      {...omit(props, ['helperText'])}
      inputProps={{
        dir: 'auto',
        ...props.inputProps,
      }}
    />
  );
}
