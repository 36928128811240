import { gql } from 'urql';

export const LOGIN_QUERY = gql`
  query Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      success
      user {
        id
        firstName
        email
      }
    }
  }
`;

export const GET_LOGGED_IN_USER_QUERY = gql`
  query LoggedInUser {
    loggedInUser {
      id
      firstName
      email
      access {
        rules
      }
      impersonatingUser {
        id
        firstName
        email
      }
    }
  }
`;

export const LIST_USERS = gql`
  query ListUsers($limit: Int, $name: String) {
    users(limit: $limit, name: $name) {
      items {
        id
        firstName
        lastName
        email
      }
      total
    }
  }
`;

export const LOGOUT_QUERY = gql`
  mutation Logout {
    logout {
      user {
        email
      }
    }
  }
`;

export const EXIT_IMPERSONATION_QUERY = gql`
  mutation ExitImpersonation {
    exitImpersonate {
      success
    }
  }
`;

export const IMPERSONATE_QUERY = gql`
  mutation Impersonate($userId: Int!) {
    impersonate(userId: $userId) {
      success
    }
  }
`;

export const GET_USER = gql`
  query GetUser($email: String!) {
    user(email: $email) {
      id
      email
      firstName
      lastName
    }
  }
`;

export const CREATE_PASSWORD_RESET = gql`
  mutation CreatePasswordReset($email: String!) {
    createPasswordReset(email: $email) {
      success
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($user: ResetPasswordInputDto!, $token: String!) {
    resetPasswordWithToken(user: $user, token: $token) {
      success
    }
  }
`;

export const USER_BY_TOKEN = gql`
  query UserByToken($token: String!, $type: UserVerificationTokenType!) {
    userByToken(token: $token, type: $type) {
      email
    }
  }
`;

export const UPDATE_USER_WITH_TOKEN = gql`
  mutation UpdateUserWithToken($token: String!, $user: UpdateUserInputDto!) {
    updateUserWithToken(token: $token, user: $user) {
      email
    }
  }
`;
